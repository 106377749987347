import { getOrganization } from "@/api/search";

const state = {
  countries: [],
  projects: [],
  cid: [],
  pid: [],
  data: "",
};

const traverse = function(arr, type, id) {
  let result = [];
  function generator(arr, type, id) {
    arr.forEach((item) => {
      if (item.type == type) {
        if (id) {
          item.parentId === id &&
            result.push({
              name: item.name,
              parentId: item.parentId,
              id: item.id,
              type: item.type,
            });
        } else {
          result.push({
            name: item.name,
            parentId: item.parentId,
            id: item.id,
            type: item.type,
          });
        }
      }
      if (!!item.secOrganizes.length) {
        generator(item.secOrganizes, type, id);
      }
    });
  }
  generator(arr, type, id);
  return result;
};

const actions = {
  async getData({ commit }, payload) {
    let res = await getOrganization({flag:1});
    let data = res.data.data;
    commit("SET_DATA", data);
    commit("CLEAR_COUNTRIES");
    commit("CLEAR_PROJECTS");
    commit("SET_COUNTRIES", traverse(data, 1));
    commit("SET_PROJECTS", traverse(data, 2));
  },
  setCountries({ state, commit }, payload) {
    let data = state.data;
    /**
     * 判断是否需要清空数组
     *     --当传入id和存储的id的数组不一致时，说明用户操作状态改变，既数据刷新
     * */ 
    if ((!!payload.id != !!state.cid.length)||!state.cid.length) {
      commit("CLEAR_COUNTRIES");
    }
    /** 
     * 判断此时传入的ID的数据是否已经展示
     *      --已展示的话过滤掉pid等于id的数据，否则继续追加数据
     * */ 
    if (state.cid.some((item) => item == payload.id)) {
      commit(
        "FILTER_COUNTRIES",
        {
            data:state.countries.filter((item) => item.parentId != payload.id),
            id:payload.id
        }
      );
    } else {
      commit("SET_COUNTRIES", traverse(data, 1, payload.id));
      commit("SET_COUNTRIES_LAST_ID", payload.id);
    }
  },
  setProjects({ state, commit }, payload) {
    let data = state.data;
    /**
     * 判断是否需要清空数组
     *     --当传入id和存储的id的数组不一致时，说明用户操作状态改变，既数据刷新
     * */ 
    if ((!!payload.id != !!state.pid.length)||!state.pid.length) {
      commit("CLEAR_PROJECTS");
    }
    /**   
     * 判断此时传入的ID的数据是否已经展示
     *      --已展示的话过滤掉pid等于id的数据，否则继续追加数据
     * */ 
    if (state.pid.some((item) => item == payload.id)) {
      commit(
        "FILTER_PROJECTS",
        {
            data:state.projects.filter((item) => item.parentId != payload.id),
            id:payload.id
        }
      );
    } else {
      commit("SET_PROJECTS", traverse(data, 2, payload.id));
      commit("SET_PROJECTS_LAST_ID", payload.id);
    }
  },
};

const mutations = {
  SET_DATA(state, payload) {
    state.data = payload;
  },
  SET_COUNTRIES(state, payload) {
    state.countries = state.countries.concat(payload);
  },
  SET_PROJECTS(state, payload) {
    state.projects = state.projects.concat(payload);
  },
  CLEAR_COUNTRIES(state) {
    state.countries = [];
  },
  CLEAR_PROJECTS(state) {
    state.projects = [];
  },
  SET_COUNTRIES_LAST_ID(state, id) {
    state.cid.push(id);
  },
  SET_PROJECTS_LAST_ID(state, id) {
    state.pid.push(id);
  },
  FILTER_COUNTRIES(state, payload) {
    state.cid = state.cid.filter((item) => item != payload.id);
    state.countries = payload.data;
    if(state.cid.length){
        state.countries = payload.data;
    }else{
        this.commit("SET_COUNTRIES", traverse(state.data, 1));
    } 
  },
  FILTER_PROJECTS(state, payload) {
    state.pid = state.pid.filter((item) => item != payload.id);
    if(state.pid.length){
        state.projects = payload.data;
    }else{
        this.commit("SET_PROJECTS", traverse(state.data, 2));
    }  
  },
};

export default {
  state,
  actions,
  mutations,
};
