import fetch from "../utils/fetch";

// 获取jssdk配置
export function getTicker(query) {
    return fetch({
        url: "/accessToken/ticket",
        method: "POST",
        data: query,
    });
}

export function Vertify() {
    return fetch({
        url: "/accessToken/verify",
        method: "GET",
    });
}
//获取用户信息
export function getUserInfo(query) {
    return fetch({
        url: "/user/login",
        method: "post",
        data: query,
    });
}
export function initInfo(mobile, data) {
    return fetch({
        url: `/importUser/${mobile}/updateByMobile`,
        method: "PUT",
        data: data,
    });
}
export function MedicalHistory() {
    return fetch({
        url: "/medicalHistory/list",
        method: "GET",
    });
}
export function VaccinesType() {
    return fetch({
        url: "/vaccinesType/list",
        method: "GET",
    });
}
export function EpidemicSave(params) {
    return fetch({
        url: "/epidemicPrevention/save",
        method: "POST",
        data: params,
    });
}
export function TripList() {
    return fetch({
        url: "/tripWay/list",
        method: "GET",
    });
}
export function TripSave(params) {
    return fetch({
        url: "/trip/save/list",
        method: "POST",
        data: params,
    });
}
export function TempList() {
    return fetch({
        url: "/abnormal/list",
        method: "GET",
    });
}

export function TempSave(params) {
    return fetch({
        url: "/temperature/save",
        method: "POST",
        data: params,
    });
}

export function HomeSave(params) {
    return fetch({
        url: "/returnInfo/save",
        method: "POST",
        data: params,
    });
}

export function IsolateType() {
    return fetch({
        url: "/isolateType/list",
        method: "GET",
    });
}

export function IsolateSave(params) {
    return fetch({
        url: "/isolate/save",
        method: "POST",
        data: params,
    });
}

export function tripList(query) {
    return fetch({
        url: "/trip/list",
        method: "GET",
        params: query,
    });
}

export function tripEdit(id, params) {
    return fetch({
        url: `/trip/${id}/update`,
        method: "PUT",
        data: params,
    });
}

export function tripDelete(id) {
    return fetch({
        url: `/trip/${id}/del`,
        method: "DELETE",
    });
}

export function importUser(mobile, params) {
    return fetch({
        url: `/importUser/${mobile}/updateByMobile`,
        method: "PUT",
        data: params,
    });
}

// 体温历史
export function tempDetail(query) {
    return fetch({
        url: "/temperature/list",
        method: "GET",
        params: query,
    });
}

// 核酸检测记录
export function nucleicList(query) {
    return fetch({
        url: "/nucleicAcid/list",
        method: "GET",
        params: query,
    });
}

// 接种疫苗记录
export function vaccinesList(query) {
    return fetch({
        url: "/vaccines/list",
        method: "GET",
        params: query,
    });
}

//
export function tripLevelList(query) {
    return fetch({
        url: "/importUser/page/multiQueryMore?ps=99999",
        method: "GET",
        params: query,
    });
}

// 疫区信息
export function areaList(query) {
    return fetch({
        url: "/riskLevel/page",
        method: "GET",
        params: query,
    });
}

// 常驻国家列表
export function countryList(query) {
    return fetch({
        url: "/companyCountry/list",
        method: "GET",
    });
}

// 国家人员数据 /importUser/distribution
export function distribution(query) {
    return fetch({
        url: "/importUser/distribution",
        method: "GET",
    });
}

// 世界疫情
export function worldEpidemic(query) {
    return fetch({
        url: `/internationalData/report`,
        method: "GET",
    });
}
// 中国 
export function chinaEpidemic(query) {
    return fetch({
        url: `/internationalData/trend`,
        method: "GET",
    });
}

// 中国人员统计
export function personChinaStatis(query) {
    return fetch({
        url: `/importUser/province/distribution`,
        method: "GET",
    });
}

// 省份人员列表
export function provincePersonList(query) {
    return fetch({
        url: `/importUser/list`,
        method: "GET",
        params: query
    });
}
// 疫苗接种修改
export function vaccineEdit(id, params) {
    return fetch({
        url: `/nucleicAcid/${id}/update`,
        method: "PUT",
        data: params
    });
}

// 疫苗接种删除
export function vaccineDelete(id) {
    return fetch({
        url: `/nucleicAcid/${id}/del`,
        method: "DELETE"
    })
}

// 中国疫情统计 /chianData/report
export function chinaEpidemicData(query) {
    return fetch({
        url: `/chianData/report`,
        method: "GET",
        params: query
    });
}

// 疫苗树
export function vaccineTree(query) {
    return fetch({
        url: '/vaccinesType/list',
        method: "get",
        params: query
    })
}
//新增疫苗类型
export function addVaccineType(query) {
    return fetch({
        url: '/vaccinesType/save',
        method: "post",
        data: query
    })
}

// 
export function returnWorkData(query) {
    return fetch({
        url: '/trip/workReport',
        method: "get",
        params: query
    })
}

export function returnWorkList(query) {
    return fetch({
        url: '/returnInfo/workPerson',
        method: "get",
        params: query
    })
}

export function getAllcounrty(query) {
    return fetch({
        url: '/internationalData/countryList',
        method: "get",
        params: query
    })
}


export function getAllcounrtyData(query) {
    return fetch({
        url: '/companyCountry/list',
        method: "get",
        params: query
    })
}

// /internationalData/countryReport?resident=
export function getCountryDetail(query) {
    return fetch({
        url: '/internationalData/countryReport',
        method: "get",
        params: query
    })
}

///riskLevel/list?cityCode=
export function getRiskHistory(query) {
    return fetch({
        url: '/riskLevel/page',
        method: "get",
        params: query
    })
}

// 获取详细信息
export function getPersonDetail(mobile) {
    return fetch({
        url: `/importUser/mobile/${mobile}`,
        method: "get",
    })
}

export function getTempDetail(mobile) {
    return fetch({
        url: `/temperature/list?mobile=${mobile}`,
        method: "get",
    })
}

export function getIsolateDetail(mobile) {
    return fetch({
        url: `/isolate/list?mobile=${mobile}`,
        method: "get",
    })
}

export function getVaccineDetail(mobile) {
    return fetch({
        url: `/vaccines/list?mobile=${mobile}`,
        method: "get",
    })
}


export function getNuclearDetail(mobile) {
    return fetch({
        url: `/nucleicAcid/list?mobile=${mobile}`,
        method: "get",
    })
}

export function EditVac(id, query) {
    return fetch({
        url: `/vaccines/${id}/update`,
        method: "PUT",
        data: query
    })
}

export function DelVac(id) {
    return fetch({
        url: `/vaccines/${id}/del`,
        method: "DELETE",
    })
}

export function EditIsolate(id, query) {
    return fetch({
        url: `/isolate/${id}/update`,
        method: "PUT",
        data: query
    })
}

export function DelIsolate(id) {
    return fetch({
        url: `/isolate/${id}/del`,
        method: "DELETE",
    })
}

export function EditTemp(id, query) {
    return fetch({
        url: `/temperature/${id}/update`,
        method: "PUT",
        data: query
    })
}

export function DelTemp(id) {
    return fetch({
        url: `/temperature/${id}/del`,
        method: "DELETE",
    })
}

export function EpidemicCount() {
    return fetch({
        url: '/internationalData/countryEpidemicCount',
        method: 'GET'
    })
}

export function getCurrentNewTrip(query) {
    return fetch({
        url: `/trip/beforeNowLatest`,
        method: 'get',
        params: query
    })
}


export function getPersonListInfo() {
    return fetch({
        url: '/trip/workReportByIns',
        method: 'get'
    })
}


export function getPolicy(data) {
    return fetch({
        url: '/user/httpBridge',
        method: "post",
        data
    })
}