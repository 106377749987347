<template>
  <div
    id="app"
    data-weui-theme="light"
    style="position: relative; min-height: 100vh; padding-bottom: 60px"
  >
    <router-view></router-view>
    <p class="rule" @click="$router.push({ name: 'Rules' })" v-if="ruleFlag">
      帮助
    </p>
  </div>
</template>
<script>
import { getUserInfo, Vertify, getTicker } from "@/api/api";
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      transitionName: "",
      ruleFlag: true,
    };
  },
  provide() {
    return {
      wxJS: this.wxJS,
    };
  },
  watch: {
    $route: function (val) {
      this.ruleFlag = val.meta.hasRule;
    },
  },
  mounted() {
   
  },
  methods: {
    wxJS() {
      let url = location.href.split('#')[0];
      getTicker({ url:url}).then((res) => {
        let config = res.data.data;
        wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: config.appId, // 必填，企业微信的corpID
          timestamp: config.timestamp, // 必填，生成签名的时间戳
          nonceStr: config.nonceStr, // 必填，生成签名的随机串
          signature: config.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: ["getLocation"], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
        wx.ready(() => {
          wx.getLocation({
            type: "wgs84",
            success: (ress) => {
              let { latitude, longitude } = ress;
              this.$jsonp(
                "https://apis.map.qq.com/ws/geocoder/v1/?key=MLMBZ-JXJCW-TXVR3-OF7AG-ZD5CF-ONFBC&output=jsonp&location=" +
                  [latitude, longitude].join(",")
              ).then((result) => {
                this.$store.commit("setPosition", result.result);
                this.$store.dispatch('position',result.result)
              });
            },
            fail: (err) => {
              console.log(err);
            },
          });
        });
        wx.error((err) => {
          console.log(err, "-------");
        });
      });
    },
  },
};
</script>
<style>
body {
  background: #f5f5f5;
}
.form {
  padding-top: 0.2rem;
}
.weui-input {
  text-align: right;
}
.weui-cell {
  background: #fff;
}
.btn-area {
  padding: 0.4rem 0.3rem;
}
.weui-icon-btn.weui-icon-btn_close.weui-picker__btn {
  display: none;
}
.weui-picker__item {
  height: 48px;
  line-height: 48px;
}
.weui-picker__indicator {
  height: 48px;
}
.weui-input {
  color: #666;
  font-size: 0.16rem;
}
.weui-input::placeholder {
  font-size: 0.16rem;
  color: #aaa;
}
.weui-toast {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.error .weui-icon_toast.weui-icon-success-no-circle {
  display: none;
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: relative;
  height: 100vh;
}
.slide-right-enter {
  opacity: 0;
  transform: translate(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.placeholder {
  font-size: 0.16rem;
  color: #aaa;
}
.weui-btn {
  font-size: 0.16rem;
}
.weui-btn_default {
  color: #2f7dcd;
  background: #fff;
}
.weui-cell__ft,
.weui-cell__bd {
  font-size: 0.16rem;
}
.alwaysAddress .weui-cell__ft {
  width: 100%;
}
.distpicker-address-wrapper select {
  font-size: 12px !important;
  padding: 5px 10px !important;
  border-radius: 3px !important;
  width: 80px !important;
  outline: none;
}
.distpicker-address-wrapper label:first-child select {
  width: 150px !important;
}
.map {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.weui-cell__bd {
  position: relative;
  max-width: 50%;
}
.weui-cell {
  justify-content: space-between;
}
.weui-cell__bd.require::after {
  content: "*";
  font-size: 1em;
  color: red;
}
.tag_btn.primary {
  background: #407ac2;
}
.tag_btn {
  min-width: 0.7rem;
  height: 0.3rem;
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  line-height: 0.3rem;
  font-size: 0.14rem;
  padding: 0 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tag_btn:active {
  opacity: 0.7;
}
.tag_btn.plain {
  background: #dae1eb;
  color: #2f7dcd;
}
.tag_btn.info {
  background: #ec5d78;
}
.tag_btn.infoplain {
  background: rgba(236, 93, 120, 0.19);
  color: #f28ea0;
}
.tag_btn .check {
  display: inline-block;
  width: 11px;
  height: 11px;
  border: 1px solid #2f7dcd;
  margin-right: 5px;
  position: relative;
  overflow: hidden;
}
.tag_btn.active .check {
  background: #2f7dcd;
}
.tag_btn.active .check::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 5px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translate(-50%, -50%) rotateZ(45deg);
  transform-origin: center;
  position: absolute;
  top: 40%;
  left: 50%;
}
.map {
  z-index: 9999;
}

.el-tree-node__content {
  height: 40px !important;
}

.rule {
  color: #2f7dcd;
  font-size: 0.14rem;
  text-align: center;
  margin: 0.2rem 0;
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.viewAllBtn {
  color: #999;
  font-size: 0.14rem;
  margin: 0 0.2rem;
  text-align: center;
}
</style>
