import Vue from "vue";
import Vuex from "vuex";
import organization from "./modules/organization";
import project from "./modules/project";
import weui from "weui.js";
import router from "../router";
Vue.use(Vuex);
const checkArray = function(arr, value) {
    if (arr.indexOf(value) > -1) {
        return arr.filter((ele) => {
            return ele != value;
        });
    } else {
        let array = arr;
        array.push(value);
        return array;
    }
};

const showTips = function() {
    weui.confirm("您当前的定位与行程记录不符，请前去填写行程记录", {
        title: "行程提醒",
        buttons: [{
                label: "取消",
                type: "default",
                onClick: function() {},
            },
            {
                label: "前往",
                type: "primary",
                onClick: function() {
                    router.push("/journey");
                },
            },
        ],
    });
};

export default new Vuex.Store({
    state: {
        mapkey: "5XHBZ-OX5LW-IPPRD-R66BI-XVDHH-IHFXO",
        axiosPromiseCancel: [],
        whiteList: ["/companyCountry/list", "/secOrganize/tree"],
        userInfo: process.env.NODE_ENV == "development" ?
            {
                name: "1232",
                mobile: "+13305423032",
                token: "Bearer 0034540f-282a-4582-a9b7-3155980a5928",
                detail: JSON.stringify({
                    addressDetail: "adsadada",
                    alwaysCountry: "以色列",
                    province: "",
                    city: "",
                    district: "",
                    country: "以色列总部",
                    depart: "",
                    gender: "男",
                    id: "",
                    idCard: "",
                    institution: "以色列公司",
                    medicalHistory: "",
                    mobile: "13305423032",
                    name: "崔延聪",
                    nationality: "",
                    passport: "",
                    post: "",
                    project: "",
                    registration: "",
                    reportName: "",
                    reportPhone: "",
                    reportTime: "",
                    role: 1,
                }),
                beforeNowLatestTrip: null,
            } :
            {},
        cacheform: {},
        currentPosition: "",
        search: [],
        tipsFlag: true,
    },
    mutations: {
        setTipsFlag(state, data) {
            state.tipsFlag = false;
        },
        setUserInfo(state, data) {
            state.userInfo = data;
        },
        setCache(state, data) {
            state.cacheform[data.key] = data.data;
        },
        clearCache(state) {
            state.cacheform = {};
        },
        setPosition(state, data) {
            state.currentPosition = data;
        },
        setSearch(state, data) {
            let result = state.search.findIndex((item) => item.type == data.type);
            let res = state.search.find((item) => item.type == data.type);
            let idx = result;
            if (!res || (res && typeof res.value !== "object")) {
                if (result < 0) {
                    state.search.push(data);
                } else {
                    if (
                        data.name == state.search[idx].name &&
                        data.type == state.search[idx].type
                    ) {
                        state.search.splice(idx, 1);
                    } else {
                        Vue.set(state.search, idx, data);
                    }
                }
            } else {
                if (state.search[idx]) {
                    let item = state.search[idx];
                    item.name = checkArray(item.name, data.name[0]);
                    item.value = checkArray(item.value, data.value[0]);
                    Vue.set(state.search, idx, item);
                } else {
                    let item = { name: [], value: [], type: data.type };
                    item.name.push(data.name[0]);
                    item.value.push(data.value[0]);
                    state.search.push(item);
                }
            }
        },
        clearSearch(state) {
            state.search = [];
        },
        delSearch(state, data) {
            let idx = state.search.findIndex((item) => {
                return item.type == data.type;
            });
            state.search.splice(idx, 1);
        },
    },
    actions: {
        position({ state, commit }, data) {
            let info = JSON.parse(state.userInfo.detail);
            let { city, province, coun, abroadName } = info;
            let country = abroadName || coun
            let beforeNowLatestTrip = state.userInfo.beforeNowLatestTrip;
            if (!state.tipsFlag) return;
            if (data.ad_info.nation != '中国') return
            if (beforeNowLatestTrip) {
                if (beforeNowLatestTrip.city != "市辖区") {
                    if (data.ad_info.city.indexOf(beforeNowLatestTrip.city) < 0) {
                        showTips();
                        commit("setTipsFlag");
                        return;
                    }
                } else {
                    if (data.ad_info.city.indexOf(beforeNowLatestTrip.province) < 0) {
                        showTips();
                        commit("setTipsFlag");
                        return;
                    }
                }
            } else {
                if (city != "市辖区") {
                    if (data.ad_info.city.indexOf(city) < 0) {
                        commit("setTipsFlag");
                        showTips();
                    }
                } else {
                    if (data.ad_info.city.indexOf(province) < 0) {
                        commit("setTipsFlag");
                        showTips();
                    }
                }

            }
        },
    },
    modules: {
        organization,
        project,
    },
    getters: {
        countries: (state) => state.organization.countries,
        projects: (state) => state.organization.projects,
        search: (state) => state.search,
        projectForm: (state) => state.project.projectForm,
    },
});