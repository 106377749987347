import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import weui from "weui.js";
import vConsole from "vconsole";
import "element-ui/lib/theme-chalk/index.css";
import element from "element-ui";
import cnchar from "cnchar";
import { VueJsonp } from "vue-jsonp";
Vue.use(element);
Vue.use(VueJsonp);
Vue.use(cnchar);
//Vue.prototype.$vConsole = new vConsole()
if (process.env.NODE_ENV !== "production") {
  Vue.prototype.$vConsole = new vConsole();
}

Vue.prototype.$weui = weui;

router.beforeEach((to, from, next) => {
  console.log(to, from);
  if (to.name == "Login" && from.name == "Index") {
    setTimeout(() => {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function() {
          WeixinJSBridge.call("closeWindow");
        },
        false
      );
      //这个可以关闭ios系统的手机
      WeixinJSBridge.call("closeWindow");
    }, 100);
  }
  document.title = to.meta.title;
  next();
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
