const state = {
  projectForm: {},
};

const mutations = {
  SET_PROJECT_FORM(state, data) {
    state.projectForm = data;
  },
};

export default {
  state,
  mutations,
};
