import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/index.vue"),
    meta: {
      title: "首页",
      hasRule:true
    },
  },
  {
    path: "/rules",
    name: "Rules",
    component: () => import("../views/rules.vue"),
    meta: {
      title: "填报规则",
    },
  },
  {
    path: "/info",
    name: "Info",
    component: () => import("../views/info/info.vue"),
    meta: {
      title: "信息确认",
      hasRule:true
    },
  },
  {
    path: "/position",
    name: "Position",
    component: () => import("../views/info/position.vue"),
    meta: {
      title: "初始化信息",
    },
  },
  {
    path: "/epidemic",
    name: "Epidemic",
    component: () => import("../views/info/epidemic.vue"),
    meta: {
      title: "核酸检测",
      hasRule:true
    },
  },
  {
    path: "/vaccine",
    name: "Vaccine",
    component: () => import("../views/info/vaccine.vue"),
    meta: {
      title: "接种疫苗",
      hasRule:true
    },
  },
  {
    path: "/temperature",
    name: "Temperature",
    component: () => import("../views/temperature/temperature.vue"),
    meta: {
      title: "体温信息",
      hasRule:true
    },
  },
  {
    path: "/postInfo",
    name: "PostInfo",
    component: () => import("../views/infopost/index.vue"),
    meta: {
      title: "信息提报",
      hasRule:true
    },
  },
  {
    path: "/quarantine",
    name: "Quarantine",
    component: () => import("../views/quarantine/index.vue"),
    meta: {
      title: "隔离登记",
      hasRule:true
    },
  },
  {
    path: "/journey",
    name: "Journey",
    component: () => import("../views/journey/index.vue"),
    meta: {
      title: "行程登记",
      hasRule:true
    },
  },
  {
    path: "/journey/history",
    name: "JourneyHistory",
    component: () => import("../views/journey/history.vue"),
    meta: {
      title: "行程历史",
    },
  },
  {
    path: "/personInfo",
    name: "PersonInfo",
    component: () => import("../views/personInfo/index.vue"),
    meta: {
      title: "人员信息",
    },
  },
  {
    path: "/personList",
    name: "personList",
    component: () => import("../views/personInfo/personList.vue"),
    meta: {
      title: "人员信息",
      hasRule:false
    },
  },
  {
    path: "/person/list",
    name: "PersonList",
    component: () => import("../views/personInfo/list.vue"),
    meta: {
      title: "员工状态查询",
    },
  },
  {
    path: "/home",
    component: () => import("../views/home/index"),
    name: "Home",
    meta: {
      title: "回国登记",
    },
  },
  {
    path: "/login",
    component: () => import("../views/login"),
    name: "Login",
    meta: {
      title: "登录",
    },
  },
  {
    path: "/epidemicArea",
    component: () => import("../views/epidemicArea/index.vue"),
    name: "EpidemicArea",
    meta: {
      title: "风险地区",
    },
  },
  {
    path: "/management",
    name: "Management",
    component: () => import("../views/management/epidemic.vue"),
    meta: {
      title: "海外疫情",
    },
  },
  {
    path: "/management/person",
    name: "Fenbu",
    component: () => import("../views/management/person.vue"),
    meta: {
      title: "人员分布",
    },
  },
  {
    path: "/management/list",
    name: "PersonFenbuList",
    component: () => import("../views/management/personList.vue"),
    meta: {
      title: "人员列表",
    },
  },
  {
    path: "/management/policy",
    name: "Policy",
    component: () => import("../views/management/policy.vue"),
    meta: {
      title: "政策查询",
    },
  },
  {
    path: "/returnwork",
    name: "ReturnWork",
    component: () => import("../views/returnWork/index.vue"),
    meta: {
      title: "人员情况",
    },
  },
  // 搜索页面
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/search/index.vue"),
    meta: {
      title: "筛选",
    },
  },
  {
    path: "redirect",
    name: "Redirect",
    component: () => import("../views/returnWork/redirect.vue"),
    meta: {
      title: "加载中",
    },
  },
  {
    path: "/returnWork/list",
    name: "ReturnWorkList",
    component: () => import("../views/returnWork/list.vue"),
    meta: {
      title: "人员列表",
    },
  },
  {
    path: "/person/detail",
    name: "PersonDetail",
    component: () => import("../views/info/detail.vue"),
    meta: {
      title: "个人信息",
    },
  },
  {
    path: "/epidemicKnowledge",
    name: "EpidemicKnowledge",
    component: () => import("../views/knowledge/index.vue"),
    meta: {
      title: "防疫知识",
    },
  },
  {
    path: "/epidemicKnowledge/:id",
    name: "EpidemicKnowledgeDetail",
    component: () => import("../views/knowledge/detail.vue"),
    meta: {
      title: "防疫知识",
    },
  },
  {
    path: "/returnwork/switch",
    name: "Switch",
    component: () => import("../views/returnWork/switch"),
    meta: {
      title: "切换组织",
    },
  },{
    path:"/detailList/:mobile",
    name:"DetailList",
    component:()=>import("../views/info/detailList.vue"),
    meta:{
      title:'历史记录'
    }
  }
];

const router = new VueRouter({
  routes,
});

export default router;
