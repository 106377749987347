import axios from "axios";
import weui from "weui.js";
import store from "../store";
import Vue from "vue";
let host = process.env.VUE_APP_BASE_URL;
let loading = null;
const vm = new Vue({
  store,
});
let cancel;
const cancelToken = axios.CancelToken;
// 防止重复请求
let removePending = (config) => {
  for (let k in store.state["axiosPromiseCancel"]) {
    if (
      store.state["axiosPromiseCancel"][k].u ===
      config.url + "&" + config.method && store.state.whiteList.indexOf(config.url)<0
    ) {
      //当前请求在数组中存在时执行函数体
      store.state["axiosPromiseCancel"][k].f(); //执行取消操作
      store.state["axiosPromiseCancel"].splice(k, 1); //把这条记录从数组中移除
      
    }
  }
};
// 创建axios实例
const service = axios.create({
  baseURL: host, // api的base_url
  timeout: 3600000, // 请求超时时间
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    if(config.params&&config.params.mobile){
      config.params.mobile = encodeURIComponent(config.params.mobile)
    }
    if(config.data&&config.data.mobile){
      config.data.mobile = encodeURIComponent(config.data.mobile)
    }
    removePending(config); //在一个ajax发送前执行一下取消操作
    config.cancelToken = new cancelToken((cancel) => {
      store.state["axiosPromiseCancel"].push({
        u: config.url + "&" + config.method,
        f: cancel,
      });
    });
    loading = weui.loading("加载中", {
      className: "custom-classname",
    });
    let token = vm.$store.state.userInfo.token;
    config.headers["Authorization"] = token;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    removePending(response.config);
    loading.hide();
    if (response.data.code == 1) {
      return response;
    } else {
      weui.toast(response.data.msg, 1500);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    loading.hide();
    // console.log(error)
    // weui.toast("服务器错误", {
    //   duration: 1500,
    //   className: "error",
    // });
    return Promise.reject(error.response);
  }
);

export default service;
